import { gql } from '@apollo/client';
import { ResponsivePie } from '@nivo/pie';
import { chain, head, sumBy } from 'lodash';
import { useMemo } from 'react';
import { FormattedNumber } from 'react-intl';
import { Box } from '@welcome-ui/box';
import { Button } from '@welcome-ui/button';
import { Drawer, useDrawerState } from '@welcome-ui/drawer';
import { Flex } from '@welcome-ui/flex';
import { BulbIcon } from '@welcome-ui/icons';
import { Stack } from '@welcome-ui/stack';
import { useDown, useTheme } from '@xstyled/styled-components';

import { Card, CardTitle, CardSubtitle } from './card';
import ProductReplacements from './product-replacements.json';
import { colorPalette } from '../colors';

export const WasteReductionOpportunityCard = ({ impactOpportunity }) => {
  const { colors, spaceToPx } = useTheme();
  const { reduceOpportunityFactor } = impactOpportunity;

  const props = {
    data: useMemo(
      () =>
        [reduceOpportunityFactor, 1 - reduceOpportunityFactor].map(
          (value, id) => ({ value, id }),
        ),
      [reduceOpportunityFactor],
    ),
    colors: useMemo(
      () => [colors['primary-500'], colors['nude-400']],
      [colors],
    ),
    margin: useMemo(() => ({ bottom: spaceToPx('3xl') }), [spaceToPx]),
  };
  return (
    <Card textAlign="center">
      <Box>
        <CardTitle variant="h2">Waste Reduction</CardTitle>
        <CardSubtitle variant="subtitle-lg" as="span">
          Optimization opportunity
        </CardSubtitle>
      </Box>
      <CardTitle variant="h1" my="xxl">
        <FormattedNumber
          value={reduceOpportunityFactor}
          style="percent"
          minimumFractionDigits={2}
        />
      </CardTitle>
      <Box h={200}>
        <ResponsivePie
          {...props}
          innerRadius={0.5}
          padAngle={1}
          borderWidth={1}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          isInteractive={false}
        />
      </Box>
    </Card>
  );
};
WasteReductionOpportunityCard.fragment = gql`
  fragment WasteReductionOpportunityFragment on ImpactOpportunity {
    reduceOpportunityFactor
  }
`;

const id = ({ waste }) =>
  `${waste.material}${waste.object ? ` ${waste.object}` : ''}`;
const arcLinkLabel = ({ data: { waste } }) =>
  `${waste.material}${waste.object ? `: ${waste.object}` : ''}`;
const arcLabelsColor = { from: 'color' };

export const ReducibleProductsCard = ({ report }) => {
  const { spaceToPx, chart } = useTheme();
  const smallScreen = useDown('lg');
  const drawerState = useDrawerState();
  const { aggregatedWaste } = report;

  const data = useMemo(
    () =>
      chain(aggregatedWaste.data)
        .filter(['target.optimized', 'reducible'])
        .groupBy(id)
        .map((items) => ({
          ...head(items),
          count: sumBy(items, 'count'),
        }))
        .orderBy(['count'], ['desc'])
        .value(),
    [aggregatedWaste.data],
  );

  const props = {
    data,
    margin: useMemo(
      () =>
        smallScreen
          ? { top: spaceToPx('sm'), bottom: spaceToPx('4xl') }
          : { top: spaceToPx('3xl'), bottom: spaceToPx('3xl') },
      [spaceToPx, smallScreen],
    ),
    legends: useMemo(() => {
      if (!smallScreen) return [];

      const labels = data.map((item, index) => ({
        id: id(item),
        label: arcLinkLabel({ data: item }),
        color: colorPalette[index % colorPalette.length],
        index,
      }));

      const labelColumns = chain(labels)
        .groupBy(({ index }) => Math.floor(index % 3))
        .values()
        .value();

      const legendProps = {
        direction: 'column',
        itemWidth: 120,
        itemHeight: 16,
        itemOpacity: 0.8,
        symbolSize: 10,
        symbolShape: 'circle',
        translateY: 60,
      };
      const legends = ['bottom-left', 'bottom-right', 'bottom'].map(
        (anchor, i) => ({
          anchor,
          data: labelColumns[i],
          ...legendProps,
        }),
      );

      return legends;
    }, [smallScreen, data]),
  };

  return (
    <Card textAlign="center">
      <CardTitle variant="h2">
        <Flex align="baseline">
          <Box flex="1 1 auto">Reducible Products</Box>
          <Drawer.Trigger
            as={Button}
            state={drawerState}
            size="lg"
            shape="square"
            variant="ghost"
            color="nude-700"
            borderColor="nude-700"
            borderWidth={0.5}
          >
            <BulbIcon />
          </Drawer.Trigger>
          <Drawer.Backdrop state={drawerState}>
            <Drawer
              aria-label="Product replacements drawer"
              state={drawerState}
              backgroundColor="nude-200"
              placement="right"
            >
              <Drawer.Close state={drawerState} w={30} h={30} />
              <Drawer.Title
                borderBottom="1px solid"
                borderBottomColor="dark-200"
              >
                Product Replacement Ideas
              </Drawer.Title>
              <Drawer.Content backgroundColor="light-900">
                Replace:
                <Stack as="ul">
                  {data.map((d) => (
                    <div key={d}>{ProductReplacements[id(d)]}</div>
                  ))}
                </Stack>
              </Drawer.Content>
              <Drawer.Footer borderTop="1px solid" borderTopColor="dark-200">
                <Button onClick={drawerState.hide} variant="tertiary">
                  Close
                </Button>
              </Drawer.Footer>
            </Drawer>
          </Drawer.Backdrop>
        </Flex>
      </CardTitle>
      <Box h="32rem">
        <ResponsivePie
          {...props}
          id={id}
          colors={colorPalette}
          arcLinkLabelsColor={arcLabelsColor}
          arcLinkLabelsTextColor={arcLabelsColor}
          arcLinkLabel={arcLinkLabel}
          theme={chart}
          value="count"
          arcLabelsSkipAngle={8}
          arcLinkLabelsSkipAngle={8}
          borderWidth={1}
          enableArcLinkLabels={!smallScreen}
        />
      </Box>
    </Card>
  );
};
ReducibleProductsCard.fragment = gql`
  fragment ReducibleProductsFragment on Report {
    aggregatedWaste {
      data {
        waste {
          material
          object
          condition
        }
        count
        target {
          optimized
        }
      }
    }
  }
`;
