import React from 'react';
import { Route, Routes as NativeRoutes } from 'react-router-dom';

import { Layout } from './components';
import { pages, hiddenPages } from './pages';

const basePath = ':orgName';

const Routes = () => (
  <NativeRoutes>
    <Route path={basePath} element={<Layout pages={pages} />}>
      {pages.map((page) => (
        <Route key={page.path} {...page} />
      ))}
    </Route>

    {hiddenPages.map((page) => (
      <Route key={page.path} {...page} />
    ))}
  </NativeRoutes>
);

export default Routes;
