import { FormattedDateTimeRange } from 'react-intl';
import { Box } from '@welcome-ui/box';
import { Text } from '@welcome-ui/text';
import { useDown } from '@xstyled/styled-components';

import { useFilter } from '../hooks';

export const FilterView = (props) => {
  const filter = useFilter();
  const isMobile = useDown('md');

  return (
    <Box textAlign="center" {...props}>
      <Text variant="h4">
        <FormattedDateTimeRange
          dateStyle={isMobile ? 'short' : 'long'}
          from={new Date(filter.from)}
          to={new Date(filter.to)}
        />
      </Text>
    </Box>
  );
};
