import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box } from '@welcome-ui/box';
import { Button } from '@welcome-ui/button';

import { useRelativeLink } from '../hooks';

export const PagesList = ({ pages, ...rest }) => {
  const createLink = useRelativeLink();
  const location = useLocation();

  return (
    <Box display="flex" flexDirection="column" {...rest}>
      {pages.map(({ title, path, icon: Icon }) => {
        const to = createLink(path);
        const isActive = to.pathname === location.pathname;
        return (
          <Button
            variant={isActive ? 'primary' : 'ghost'}
            size="lg"
            borderRadius={0}
            borderWidth={0}
            justifyContent="flex-start"
            key={path}
            as={RouterLink}
            to={to}
          >
            <Icon mr="sm" /> {title}
          </Button>
        );
      })}
    </Box>
  );
};
