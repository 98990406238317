import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { fromBase64 } from '@ameru/shared/base-64';

import { WiFiNetworkOptions } from './wifi-network-options';

const useWiFiNetworks = () => {
  const { base64Networks } = useParams();

  return useMemo(
    () =>
      JSON.parse(fromBase64(base64Networks)).map(
        ([ssid, security, strength]) => ({
          ssid,
          security,
          strength,
        }),
      ),
    [base64Networks],
  );
};

export const WiFiPicker = ({ onChange }) => {
  const networks = useWiFiNetworks();

  return <WiFiNetworkOptions networks={networks} onChange={onChange} />;
};
