// Handle "The Unicode Problem" with usage of atob/btoa
// More info: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem

export const toBase64 = (inputStr) => {
  const bytes = new TextEncoder().encode(inputStr);
  const binaryString = String.fromCodePoint(...bytes);
  const result = btoa(binaryString);

  return result;
};

export const fromBase64 = (base64Value) => {
  const atobDecoded = atob(base64Value);
  const bytes = Uint8Array.from(atobDecoded, (m) => m.codePointAt(0));
  const result = new TextDecoder().decode(bytes);

  return result;
};
