import React, { useState, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Text } from '@welcome-ui/text';
import { Flex } from '@welcome-ui/flex';

import { RadioButtonGroup, Loader, PageTitle } from '../components';
import {
  DrillDownButton,
  useWasteSankeyData,
  WasteFlowSankey,
} from '../components/sankey';
import { useFilter, useOrgName } from '../hooks';

const getFilteredWasteReport = gql`
  ${WasteFlowSankey.fragment}
  query WasteFlow($orgName: String!, $filter: ReportInput!) {
    organization(name: $orgName) {
      id
      report(filter: $filter) {
        id
        aggregatedWaste {
          ...AllAggregatedWasteDataFragment
        }
      }
    }
  }
`;

const Targets = [
  { value: 'current', label: 'Current' },
  { value: 'opportunity', label: 'Optimization Opportunity' },
  { value: 'ideal', label: 'Long-term Goal' },
];

/* istanbul ignore next */
export const FlowPage = () => {
  const orgName = useOrgName();
  const filter = useFilter();
  const { loading, data: queryData } = useQuery(getFilteredWasteReport, {
    variables: { orgName, filter },
  });

  const [selectedTarget, setSelectedTarget] = useState(Targets[0]);
  const [drillDownLabel, setDrillDownLabel] = useState(null);

  const aggregatedWaste = queryData?.organization?.report?.aggregatedWaste;
  const total = aggregatedWaste?.total;

  const sankeyData = useWasteSankeyData(
    aggregatedWaste?.data,
    selectedTarget.value,
    { drillDownLabel },
  );

  const clearDrillDown = useCallback(() => setDrillDownLabel(null), []);

  const handleSelectTarget = useCallback(
    (target) => {
      clearDrillDown(null);
      setSelectedTarget(target);
    },
    [clearDrillDown],
  );

  if (loading) return <Loader />;

  return (
    <Flex w="100%" direction="column" p="lg">
      <PageTitle>Waste Flow</PageTitle>
      <RadioButtonGroup
        options={Targets}
        value={selectedTarget}
        onChange={handleSelectTarget}
      />
      <Flex align="center" justify="space-between">
        <Text>
          Total Waste: <strong>{total}</strong> items
        </Text>
        <DrillDownButton label={drillDownLabel} onClick={clearDrillDown} />
      </Flex>
      <WasteFlowSankey data={sankeyData} onNodeClick={setDrillDownLabel} />
    </Flex>
  );
};
