import { Loader as WuiLoader } from '@welcome-ui/loader';

export const Loader = (props) => (
  <WuiLoader
    w="100%"
    h="100%"
    size="lg"
    m="xxl"
    color="primary-500"
    justifyContent="center"
    aria-label="Loading"
    {...props}
  />
);
