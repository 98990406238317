import { useLocation } from 'react-router-dom';

import { useOrgName } from './org';

export const useRelativeLink = () => {
  const location = useLocation();
  const orgName = useOrgName();

  return (path) => ({
    pathname: `/${orgName}/${path}`,
    search: location.search,
  });
};
