import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@welcome-ui/link';
import { Text } from '@welcome-ui/text';
import { Button } from '@welcome-ui/button';
import { Stack } from '@welcome-ui/stack';
import { Box } from '@welcome-ui/box';

const SampleReportUrl = '/st6/?token=pbc5jcv2GWD@ygh4xed';

const HelpLink = () => <Link href="mailto:ops@ameru.ai">ops@ameru.ai</Link>;

const SampleReportButton = () => (
  <Button as={RouterLink} to={SampleReportUrl}>
    Sample Report
  </Button>
);

const ErrorContent = ({ paragraphs }) => (
  <>
    {paragraphs.map((paragraph, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Text key={index} variant="lg">
        {paragraph}
      </Text>
    ))}
    <SampleReportButton />
  </>
);

const ErrorMessages = {
  UNAUTHENTICATED: [
    <>
      It looks like you&apos;ve received a wrong link.
      <br />
      Don&apos;t worry! You can contact us at <HelpLink /> to get a new one.
    </>,
    <>In the meantime, you can check this out.</>,
  ],
  NOT_FOUND: [
    <>
      It looks like you&apos;re trying to get a report that doesn&apos;t exist.
    </>,
    <>Well, it&apos;s not there, but you can check this out.</>,
  ],
  UNKNOWN: [
    <>
      It looks like something unexpected happened.
      <br />
      Don&apos;t worry! You can try again later.
    </>,
    <>In the meantime, you can check this out</>,
  ],
};

export const Error = ({ error }) => {
  const paragraphs = useMemo(() => {
    const allErrorsCodes = [
      ...(error?.networkError?.result?.errors ?? []),
      ...(error?.graphQLErrors ?? []),
    ].map((e) => e?.extensions?.code);

    const matchedCode = Object.keys(ErrorMessages).find((code) =>
      allErrorsCodes.includes(code),
    );

    return ErrorMessages[matchedCode] ?? ErrorMessages.UNKNOWN;
  }, [error]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stack m="lg" spacing="0">
        <Text variant="h0">Oops!</Text>
        <ErrorContent paragraphs={paragraphs} />
      </Stack>
    </Box>
  );
};
