import { WuiProvider } from '@welcome-ui/core';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { ApolloProvider } from './client';
import { IntlProvider } from './intl';
import Routes from './routes';
import { theme } from './theme';

ReactGA.initialize('G-QNTZRSTKX5', {
  testMode: process.env.NODE_ENV === 'development',
});

function App() {
  return (
    <ApolloProvider>
      <IntlProvider>
        <WuiProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </WuiProvider>
      </IntlProvider>
    </ApolloProvider>
  );
}

export default App;
