import React from 'react';
import { Button } from '@welcome-ui/button';
import { CrossIcon } from '@welcome-ui/icons';

export const DrillDownButton = ({ label, ...rest }) =>
  label ? (
    <Button size="sm" variant="secondary-warning" {...rest}>
      <CrossIcon />
      {label}
    </Button>
  ) : null;
