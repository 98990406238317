import { x } from '@xstyled/styled-components';
import { useState, useMemo } from 'react';

import { SelectWiFiLayout } from './select-wifi-layout';
import { WiFiPasswordForm, CustomWiFiForm } from './wifi-form';
import { WiFiQRCodeStep } from './wifi-qr-code-step';
import { WiFiPicker } from './wifi-picker';
import { Button } from './button';

const SectionSeparator = () => (
  <x.div
    position="fixed"
    left={-1}
    right={-1}
    h={66}
    w={1}
    borderTop={1}
    borderColor="gray"
    borderRadius={32}
    opacity={0.3}
  />
);

const selectWiFiTitle = (
  <>
    Select the Wi-Fi
    <br />
    Network
  </>
);

const customNetworkTitle = (
  <>
    Enter network
    <br />
    Information
  </>
);

const qRCodeTitle = (
  <>
    Place the phone
    <br />
    on the receptacle
  </>
);

const customNetworkTemplate = { ssid: '', security: '', password: '' };
const isCompleteNetwork = (network) => {
  if (!network) {
    return false;
  }

  const { ssid, security, password } = network;
  if (ssid && security === 'NONE') {
    return true;
  }

  return Boolean(ssid && security && password);
};

export const SelectWiFiPage = () => {
  const [network, setNetwork] = useState();
  const cancelButton = useMemo(
    () => (
      <Button aria-label="cancel" onClick={() => setNetwork(null)}>
        Cancel
      </Button>
    ),
    [setNetwork],
  );

  if (isCompleteNetwork(network)) {
    return (
      <SelectWiFiLayout title={qRCodeTitle} footer={cancelButton}>
        <WiFiQRCodeStep network={network} />
      </SelectWiFiLayout>
    );
  }

  if (!network) {
    return (
      <SelectWiFiLayout
        title={selectWiFiTitle}
        footer={
          <Button
            w={1}
            border="1px solid"
            borderColor="gray"
            borderRadius="24px"
            fontWeight="semibold"
            aria-label="other"
            onClick={() => setNetwork(customNetworkTemplate)}
          >
            Other...
          </Button>
        }
      >
        <WiFiPicker onChange={setNetwork} />
      </SelectWiFiLayout>
    );
  }

  const isCustomNetwork = network === customNetworkTemplate;
  if (isCustomNetwork) {
    return (
      <SelectWiFiLayout title={customNetworkTitle} footer={cancelButton}>
        <CustomWiFiForm onSubmit={setNetwork} />
      </SelectWiFiLayout>
    );
  }

  return (
    <SelectWiFiLayout title={selectWiFiTitle} footer={cancelButton}>
      <SectionSeparator />

      <x.h2 textAlign="center" fontSize="1.5rem" fontWeight="black">
        Enter the password
        <br />
        <x.span fontWeight="normal">for &quot;{network.ssid}&quot;</x.span>
      </x.h2>

      <WiFiPasswordForm
        onSubmit={(password) => setNetwork((prev) => ({ ...prev, password }))}
      />
    </SelectWiFiLayout>
  );
};
