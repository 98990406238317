/* eslint-disable react/jsx-no-target-blank */
import { gql } from '@apollo/client';
import { Box } from '@welcome-ui/box';

import { Logo } from './logo';

export const Footer = ({ organization }) => (
  <Box
    alignItems="center"
    as="footer"
    display="flex"
    justifyContent="space-between"
    borderTop="1px solid"
    borderTopColor="nude-400"
    fontSize="sm"
    mb="md"
    mt="xl"
    mx={{ xs: 'md', md: 'xl' }}
    pt="xl"
  >
    <Box display={{ xs: 'none', md: 'flex' }} alignItems="center">
      <a target="_blank" href="https://ameru.ai">
        <Logo width="36" />
      </a>
      © {new Date().getFullYear()} Ameru
    </Box>
    {organization?.employees && (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        flex="1 1 auto"
      >
        Data is based on approximate occupancy of
        <strong>&nbsp;{organization?.employees}&nbsp;</strong> humans.
      </Box>
    )}
  </Box>
);

Footer.fragment = gql`
  fragment FooterOrgDetailsFragment on Organization {
    id
    employees
  }
`;
