import { x } from '@xstyled/styled-components';

import { MdWifi, MdWifi2Bar } from 'react-icons/md';
import { FaLock } from 'react-icons/fa6';

const strongSignalThreshold = 2;

const RadioButtonHidden = (props) => (
  <x.input
    position="absolute"
    opacity={0} // hide on safari
    w={0}
    h={0}
    type="radio"
    {...props}
  />
);

const WiFiOption = ({ ssid, security, strength, onChange }) => {
  const SignalStrengthIcon =
    strength > strongSignalThreshold ? MdWifi : MdWifi2Bar;

  return (
    <x.div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="platinumGray"
    >
      <x.label
        display="flex"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="platinumGray"
        w={1}
        py={24}
        px={8}
      >
        <RadioButtonHidden
          name="Select WiFi network"
          onChange={onChange}
          required
        />

        <SignalStrengthIcon size={18} />

        <x.span
          flexGrow={1}
          ml={20}
          text="2xl"
          fontWeight="semibold"
          color="gray"
        >
          {ssid}
        </x.span>

        {security && security !== 'NONE' ? <FaLock /> : null}
      </x.label>
    </x.div>
  );
};

export const WiFiNetworkOptions = ({ networks, onChange }) =>
  networks.map((network) => (
    <WiFiOption
      key={network.ssid}
      onChange={() => onChange(network)}
      {...network}
    />
  ));
