import { gql } from '@apollo/client';
import { ResponsiveStream } from '@nivo/stream';
import { Box } from '@welcome-ui/box';
import { useDown, useTheme } from '@xstyled/styled-components';
import { chain } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { CardBox, NumberCard } from './card';
import { colorPalette } from '../colors';

export const DailyUsageCard = ({ report }) => {
  const value = report.aggregatedWaste.total / report.workingDays;
  return (
    <NumberCard
      title="Daily Usage"
      subtitle="Items Observed"
      value={value}
      valueDescription="items per day"
      maximumFractionDigits={0}
    />
  );
};
DailyUsageCard.fragment = gql`
  fragment DailyUsageFragment on Report {
    aggregatedWaste {
      total
    }
    workingDays
  }
`;

export const WeeklyBinUsageCard = ({ organization }) => {
  const intl = useIntl();
  const { spaceToPx, chart } = useTheme();
  const smallScreen = useDown('lg');
  const { bins } = organization;

  const props = useMemo(() => {
    const keys = bins.map((b) => b.name);
    const groupedData = chain(bins)
      .flatMap((b) =>
        b.report.weeklyWaste.map((w) => ({ ...w, binName: b.name })),
      )
      .groupBy((w) => w.start)
      .mapValues((g) =>
        g.reduce(
          (result, { waste, binName }) =>
            Object.assign(result, { [binName]: waste.total }),
          Object.fromEntries(keys.map((binName) => [binName, 0])),
        ),
      )
      .value();

    const ticks = Object.keys(groupedData).sort();
    const data = ticks.map((key) => groupedData[key]);

    return {
      data,
      keys,
      ticks,
      axisBottom: {
        tickRotation: -45,
        format: (i) => intl.formatDate(ticks[i], { format: 'axis' }),
      },
    };
  }, [bins, intl]);

  const layoutProps = useMemo(
    () => ({
      curve: 'monotoneX',
      borderWidth: 1,
      borderColor: {
        from: 'color',
        modifiers: [['darker', 0.4]],
      },
      legends: [
        {
          anchor: smallScreen ? 'bottom' : 'bottom-right',
          direction: smallScreen ? 'row' : 'column',
          translateX: smallScreen ? 0 : 100,
          translateY: smallScreen ? 60 : 0,
          itemWidth: 80,
          itemHeight: 20,
          symbolSize: 12,
          symbolShape: 'circle',
        },
      ],
      margin: smallScreen
        ? {
            top: spaceToPx('xl'),
            right: spaceToPx('xl'),
            bottom: spaceToPx('4xl'),
            left: spaceToPx('xxl'),
          }
        : {
            top: spaceToPx('xl'),
            right: spaceToPx('6xl'),
            bottom: spaceToPx('3xl'),
            left: spaceToPx('3xl'),
          },
    }),
    [smallScreen, spaceToPx],
  );

  return (
    <CardBox title="Weekly Usage" subtitle="items observed per bin">
      <Box h={`${smallScreen ? 16 : 24}rem`}>
        <ResponsiveStream
          theme={chart}
          offsetType="none"
          colors={colorPalette}
          {...props}
          {...layoutProps}
        />
      </Box>
    </CardBox>
  );
};
WeeklyBinUsageCard.fragment = gql`
  fragment WeeklyBinUsageFragment on Organization {
    bins {
      id
      name
      report(filter: $filter) {
        id
        weeklyWaste {
          start
          waste {
            total
          }
        }
      }
    }
  }
`;
