import { styled } from '@xstyled/styled-components';

import { Button } from './button';
import {
  WiFiSSIDInput,
  WiFiSecurityInput,
  WiFiPasswordInput,
} from './wifi-input';

const StyledForm = styled.form`
  text-align: center;
  &:invalid button[type='submit'] {
    opacity: 0.5;
  }
`;

const Form = ({ children, ...rest }) => (
  <StyledForm {...rest}>
    {children}

    <Button
      mt={30}
      type="submit"
      py={8}
      px={64}
      bg="success"
      color="white"
      fontSize="1.75rem"
      fontWeight="bold"
      borderRadius={42}
      textTransform="uppercase"
    >
      Next
    </Button>
  </StyledForm>
);

export const WiFiPasswordForm = ({ onSubmit }) => (
  <Form
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit(e.target.elements.password.value);
    }}
  >
    <WiFiPasswordInput required />
  </Form>
);

export const CustomWiFiForm = ({ onSubmit }) => (
  <Form
    onSubmit={(e) => {
      e.preventDefault();

      const {
        ssid: { value: ssid },
        security: { value: security },
        password: { value: password },
      } = e.target.elements;
      onSubmit({ ssid, security, password });
    }}
  >
    <WiFiSSIDInput required />
    <WiFiSecurityInput required />
    <WiFiPasswordInput required />
  </Form>
);
