import { IntlProvider as Provider } from 'react-intl';

export const formats = {
  number: {
    EUR: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
  },
  date: {
    axis: {
      month: 'short',
      day: 'numeric',
    },
  },
};

export const IntlProvider = ({ children }) => (
  <Provider locale={navigator.language} formats={formats}>
    {children}
  </Provider>
);
