import { cleanEnv, url } from 'envalid';

const env = cleanEnv(process.env, {
  REACT_APP_API_URL: url({
    devDefault: '/',
  }),
});

export const api = {
  url: env.REACT_APP_API_URL,
};
