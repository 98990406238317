import { Button } from '@welcome-ui/button';
import { ButtonGroup } from '@welcome-ui/button-group';
import { styled, useDown } from '@xstyled/styled-components';

const SButton = styled(Button)`
  &:not(:last-child) {
    border-right-width: 0;
  }
`;

export const RadioButtonGroup = ({ options, value, onChange }) => {
  const smallScreen = useDown('md');

  return (
    <ButtonGroup size={smallScreen ? 'sm' : 'md'}>
      {options.map((opt) => (
        <SButton
          key={opt.value}
          onClick={() => onChange(opt)}
          variant={opt === value ? 'primary' : 'tertiary'}
        >
          {opt.label}
        </SButton>
      ))}
    </ButtonGroup>
  );
};
