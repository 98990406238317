import { gql, useQuery } from '@apollo/client';
import { Outlet } from 'react-router-dom';
import { Box } from '@welcome-ui/box';
import styled, { th } from '@xstyled/styled-components';

import { Footer } from './footer';
import { Header, headerHeight } from './header';
import { PagesList } from './pages-list';
import { useOrgName } from '../hooks';
import { Loader } from './loader';
import { Error } from './error';

const navigationWidth = '12rem';

const Navigation = styled.nav`
  display: none;
  position: fixed;
  width: ${navigationWidth};
  top: ${headerHeight};
  left: 0;
  bottom: 0;
  border-right: 1px solid ${th('colors.border')};
  overflow: auto;
  @media (min-width: xl) {
    display: block;
  }
`;

const Content = styled.main`
  width: 100%;
  min-height: ${`calc(100vh - ${headerHeight})`};
  > :not(footer) {
    max-width: xl;
    padding: md;
    margin: 0 auto;
  }
  @media (min-width: xl) {
    padding-left: ${navigationWidth};
    > :not(footer) {
      padding: lg;
    }
  }
`;

export const getOrgDetails = gql`
  ${Header.fragment}
  ${Footer.fragment}
  query OrgBasicDetails($orgName: String!) {
    organization(name: $orgName) {
      id
      ...HeaderOrgDetailsFragment
      ...FooterOrgDetailsFragment
    }
  }
`;

/* istanbul ignore next */
export const Layout = ({ pages }) => {
  const orgName = useOrgName();
  const { loading, error, data } = useQuery(getOrgDetails, {
    variables: { orgName },
  });

  return (
    <Box>
      <Header pages={pages} organization={data?.organization} />
      <Box display="flex" backgroundColor="nude-300">
        <Navigation>
          <PagesList pages={pages} />
        </Navigation>
        <Content>
          <Box maxW={1280}>
            {loading && <Loader />}
            {error && <Error error={error} />}
            {!loading && !error && <Outlet />}
          </Box>
          <Footer organization={data?.organization} />
        </Content>
      </Box>
    </Box>
  );
};
