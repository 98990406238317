import { x } from '@xstyled/styled-components';
import QRCode from 'react-qr-code';

// MECARD like escaping
// More info: https://github.com/zxing/zxing/wiki/Barcode-Contents#wi-fi-network-config-android-ios-11
const sanitize = (value) =>
  value
    .replaceAll('\\', '\\\\')
    .replaceAll(';', '\\;')
    .replaceAll(',', '\\,')
    .replaceAll('"', '\\"')
    .replaceAll(':', '\\:');

const getWiFiConfigAndroidIOS11 = ({ ssid, security, password = '' }) => {
  const transformedSecurity = security === 'NONE' ? 'nopass' : security;
  const securitySegment = `T:${sanitize(transformedSecurity)}`;
  const ssidSegment = `S:${sanitize(ssid)}`;
  const passwordSegment = password ? `P:${sanitize(password)}` : '';

  return `WIFI:${securitySegment};${ssidSegment};${passwordSegment};;`;
};

export const WiFiQRCodeStep = ({ network }) => (
  <>
    <x.div mb={12} color="success" textAlign="center">
      Decrease your screen brightness.
    </x.div>

    <x.div
      p="1rem"
      textAlign="center"
      border="2px solid"
      borderColor="platinumGray"
      borderRadius={14}
    >
      <QRCode
        role="img"
        size={260}
        value={getWiFiConfigAndroidIOS11(network)}
      />
    </x.div>
  </>
);
