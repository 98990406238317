import { x } from '@xstyled/styled-components';

export const Button = ({ children, ...rest }) => (
  <x.button
    py={12}
    px={32}
    bg="white"
    border="none"
    fontSize="1.25rem"
    color="gray"
    {...rest}
  >
    {children}
  </x.button>
);
