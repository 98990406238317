import { gql } from '@apollo/client';

import { NumberCard } from './card';

export const CO2SavingsInCarsCard = ({ impactOpportunity }) => (
  <NumberCard
    title="Environment"
    subtitle="CO2 reduction impact"
    value={impactOpportunity.CO2SavingsInCars}
    maximumFractionDigits={1}
    valueDescription="cars removed per year"
  />
);
CO2SavingsInCarsCard.fragment = gql`
  fragment CO2SavingsInCarsFragment on ImpactOpportunity {
    CO2SavingsInCars
  }
`;

export const MunicipalSavingsCard = ({ impactOpportunity }) => (
  <NumberCard
    title="Municipal Savings"
    subtitle="Waste Disposal Fees"
    value={impactOpportunity.municipalSavings}
    valueDescription="per year"
    format="EUR"
  />
);
MunicipalSavingsCard.fragment = gql`
  fragment MunicipalSavingsFragment on ImpactOpportunity {
    municipalSavings
  }
`;

export const OfficeSavingsCard = ({ impactOpportunity }) => (
  <NumberCard
    title="Office Savings"
    subtitle="Waste Disposal"
    value={impactOpportunity.officeSavings}
    valueDescription="per year"
    format="EUR"
  />
);
OfficeSavingsCard.fragment = gql`
  fragment OfficeSavingsFragment on ImpactOpportunity {
    officeSavings
  }
`;

export const TotalSavingsCard = ({ impactOpportunity }) => (
  <NumberCard
    title="Total Economic Impact"
    subtitle="Municipal & office waste disposal savings"
    value={impactOpportunity.totalSavings}
    valueDescription="per year"
    format="EUR"
  />
);
TotalSavingsCard.fragment = gql`
  fragment TotalSavingsFragment on ImpactOpportunity {
    totalSavings
  }
`;
