import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { Box } from '@welcome-ui/box';
import { Card as WuiCard } from '@welcome-ui/card';
import { Text } from '@welcome-ui/text';

export const CardTitle = ({ children, variant = 'h3', ...rest }) => (
  <Text variant={variant} color="dark-700" my="sm" {...rest}>
    {children}
  </Text>
);

export const CardSubtitle = ({ children, ...rest }) => (
  <Text variant="subtitle-md" color="dark-400" {...rest}>
    {children}
  </Text>
);

export const Card = ({ children, ...rest }) => (
  <WuiCard backgroundColor="white" h="100%" borderRadius={3} {...rest}>
    <WuiCard.Body
      fontWeight="bold"
      h="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      {children}
    </WuiCard.Body>
  </WuiCard>
);

export const CardBox = ({ title, subtitle, children }) => (
  <Card>
    <Box>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle>{subtitle}</CardSubtitle>
    </Box>
    {children}
  </Card>
);

const NumberValue = (props) => (
  <CardTitle variant="h1">
    <FormattedNumber {...props} />
  </CardTitle>
);

export const NumberCard = ({ title, subtitle, valueDescription, ...rest }) => (
  <CardBox title={title} subtitle={subtitle}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <NumberValue {...rest} />
      <Text mt="0" variant="md" color="dark-400">
        {valueDescription}
      </Text>
    </Box>
  </CardBox>
);
