/* eslint-disable global-require */
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app';

if (
  // Do not bundle mocks in production
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCK_SERVER === 'true'
) {
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
