export const colorMap = {
  Air: '#A5988D',
  Bio: '#D18F6A',
  Calorific: '#FF7B7B',
  Cardboard: '#4ACFFF',
  Cash: '#9BF048',
  Compost: '#625836',
  Electronics: '#DC7676',
  Glass: '#70B62C',
  Landfill: '#BDAEA1',
  Metal: '#B9B9B9',
  Mixed: '#968CD3',
  Other: '#7D7D7D',
  Paper: '#04BDFF',
  Plastic: '#E9B603',
  Porcelain: '#F0F0F0',
  Recyclable: '#96BA89',
  Reducible: '#A5988D',
  Styrofoam: '#ECC3C1',
  Tetrapak: '#31E5DB',
  Textiles: '#D2D38C',
  Trash: '#BA8989',
  Unknown: '#413838',
  Waste: '#8C745D',
  Wood: '#927270',
  'Soiled Plastic': '#76D6DC',
  'Soiled Paper': '#BAAC89',
  'Soiled Cardboard': '#874A76',
};

export const colorPalette = [
  '#BF3459',
  '#484466',
  '#96D2D9',
  '#77D9AB',
  '#F2C777',
  '#F2836B',
  '#0367A6',
  '#DDD9D6',
  '#A18786',
  '#743A31',
  '#B35297',
];
