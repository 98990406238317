import React, { memo, useCallback, useMemo } from 'react';
import { gql } from '@apollo/client';
import { Box } from '@welcome-ui/box';
import { ResponsiveSankey } from '@nivo/sankey';
import { BasicTooltip, Chip } from '@nivo/tooltip';
import { useTheme } from '@xstyled/styled-components';

const SankeyLinkTooltip = ({ link }) => {
  const { tooltip } = useTheme().sankeyChart;

  return (
    <BasicTooltip
      id={
        <span style={tooltip.container} role="tooltip">
          <Chip color={link.source.color} style={tooltip.sourceChip} />
          <strong>{link.source.id}</strong>
          {' > '}
          <strong>{link.target.id}</strong>
          <Chip color={link.target.color} style={tooltip.targetChip} />
          <strong>{link.formattedValue}</strong>
        </span>
      }
    />
  );
};

const SankeyNodeTooltip = ({ node }) => {
  const { tooltip } = useTheme().sankeyChart;

  return (
    <BasicTooltip
      id={
        <span style={tooltip.container} role="tooltip">
          <Chip color={node.color} style={tooltip.sourceChip} />
          <strong>{node.label}</strong>
        </span>
      }
    />
  );
};

const getNodeLabel = ({ id, count }) => `${id}: ${count}`;

export const WasteFlowSankey = memo(function WasteFlowSankey({
  data,
  onNodeClick,
}) {
  const { sankeyChart, spaceToPx } = useTheme();
  const props = {
    data,
    onClick: useCallback(({ id }) => id && onNodeClick(id), [onNodeClick]),
    label: getNodeLabel,
    linkTooltip: SankeyLinkTooltip,
    nodeTooltip: SankeyNodeTooltip,
    margin: useMemo(
      () => ({ top: spaceToPx('sm'), bottom: spaceToPx('sm') }),
      [spaceToPx],
    ),
    theme: sankeyChart,
    nodeBorderColor: 0,
    enableLinkGradient: true,
    colors: (node) => node.color,
  };

  return data ? (
    <Box h={500} w="100%">
      <ResponsiveSankey {...props} />
    </Box>
  ) : null;
});

WasteFlowSankey.fragment = gql`
  fragment AllAggregatedWasteDataFragment on AggregatedWaste {
    total
    data {
      waste {
        material
        object
        condition
      }
      target {
        recyclable
        optimized
        ideal
      }
      count
    }
  }
`;
