/* istanbul ignore file */
import { gql, useQuery } from '@apollo/client';
import { Grid } from '@welcome-ui/grid';
import { Text } from '@welcome-ui/text';

import { Loader } from '../components';
import {
  DailyUsageCard,
  WeeklyBinUsageCard,
  CO2SavingsInCarsCard,
  MunicipalSavingsCard,
  OfficeSavingsCard,
  TotalSavingsCard,
  WasteReductionOpportunityCard,
  ReducibleProductsCard,
} from '../components/cards';
import { useFilter, useOrgName } from '../hooks';

const getImpactOpportunity = gql`
  ${DailyUsageCard.fragment}
  ${WeeklyBinUsageCard.fragment}
  ${CO2SavingsInCarsCard.fragment}
  ${MunicipalSavingsCard.fragment}
  ${OfficeSavingsCard.fragment}
  ${TotalSavingsCard.fragment}
  ${WasteReductionOpportunityCard.fragment}
  ${WasteReductionOpportunityCard.fragment}
  ${ReducibleProductsCard.fragment}
  query ImpactOpportunity($orgName: String!, $filter: ReportInput!) {
    organization(name: $orgName) {
      id
      ...WeeklyBinUsageFragment
      report(filter: $filter) {
        id
        ...DailyUsageFragment
        ...ReducibleProductsFragment
        impactOpportunity {
          ...CO2SavingsInCarsFragment
          ...MunicipalSavingsFragment
          ...OfficeSavingsFragment
          ...TotalSavingsFragment
          ...WasteReductionOpportunityFragment
        }
      }
    }
  }
`;

const SectionHeader = ({ children, ...rest }) => (
  <Grid.Item column="1/-1" {...rest}>
    <Text variant="h3" mt="md" mb={0}>
      {children}
    </Text>
  </Grid.Item>
);

export const HomePage = () => {
  const orgName = useOrgName();
  const filter = useFilter();
  const { loading, data } = useQuery(getImpactOpportunity, {
    variables: { orgName, filter },
  });
  if (loading) return <Loader />;

  const { organization } = data;
  const { report } = organization;
  const { impactOpportunity } = report;

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="md">
      <SectionHeader>Waste Optimization</SectionHeader>
      <Grid.Item column={{ _: 'span 12', md: 'span 3' }}>
        <WasteReductionOpportunityCard impactOpportunity={impactOpportunity} />
      </Grid.Item>
      <Grid.Item column={{ _: 'span 12', md: 'span 9' }}>
        <ReducibleProductsCard report={report} />
      </Grid.Item>
      <SectionHeader mt="md">Environmental & Social Impact</SectionHeader>
      <Grid.Item column={{ _: 'span 6', lg: 'span 4' }}>
        <CO2SavingsInCarsCard impactOpportunity={impactOpportunity} />
      </Grid.Item>
      <Grid.Item column={{ _: 'span 6', lg: 'span 4' }}>
        <MunicipalSavingsCard impactOpportunity={impactOpportunity} />
      </Grid.Item>
      <Grid.Item column={{ _: 'span 6', lg: 'span 4' }}>
        <OfficeSavingsCard impactOpportunity={impactOpportunity} />
      </Grid.Item>
      <Grid.Item column="span 6">
        <TotalSavingsCard impactOpportunity={impactOpportunity} />
      </Grid.Item>
      <SectionHeader mt="md">Usage</SectionHeader>
      <Grid.Item column={{ _: 'span 12', md: 'span 3' }}>
        <DailyUsageCard report={report} />
      </Grid.Item>
      <Grid.Item column={{ _: 'span 12', md: 'span 9' }}>
        <WeeklyBinUsageCard organization={organization} />
      </Grid.Item>
    </Grid>
  );
};
