import { useSearchParams } from 'react-router-dom';

const formatDate = (date) => date.toISOString().split('T')[0];

export const useFilter = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const lastMonthFrom = new Date(Date.UTC(year, month - 1, 1));
  const lastMonthTo = new Date(Date.UTC(year, month, 0));

  const [searchParams] = useSearchParams({
    from: formatDate(lastMonthFrom),
    to: formatDate(lastMonthTo),
  });

  return {
    from: searchParams.get('from'),
    to: searchParams.get('to'),
  };
};
