import { x, styled } from '@xstyled/styled-components';

// Use vh fallbacks for devices that do not support dvh.
// Why we need dvh? Because of dynamic address bar height on iOS 15.4+
// More info: https://webkit.org/blog/12445/new-webkit-features-in-safari-15-4/
const Container = styled.div`
  position: relative;

  height: 94vh;
  height: 94dvh;

  margin: 3vh 24;
  margin: 3dvh 24;
`;

const Body = styled.div`
  height: 65vh;
  height: 65dvh;

  margin-top: 6vh;
  margin-top: 6dvh;

  overflow-y: auto;
`;

export const SelectWiFiLayout = ({ title, children, footer }) => (
  <Container>
    <x.h1 m={0} textAlign="center" fontWeight="black" fontSize="2rem">
      {title}
    </x.h1>

    <Body>{children}</Body>

    <x.div position="absolute" left={0} right={0} bottom={0} textAlign="center">
      {footer}
    </x.div>
  </Container>
);
