import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Box } from '@welcome-ui/box';
import { Button } from '@welcome-ui/button';
import { Drawer, useDrawerState } from '@welcome-ui/drawer';
import { CrossIcon, MenuIcon } from '@welcome-ui/icons';
import { Text } from '@welcome-ui/text';
import styled, { css, th } from '@xstyled/styled-components';

import { Logo } from './logo';
import { FilterView } from './filter-view';
import { useRelativeLink } from '../hooks';
import { PagesList } from './pages-list';

export const headerHeight = '3.75rem';

const StyledHeader = styled.header(
  () => css`
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${headerHeight};
    padding: 0 md;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
    border-bottom: 1px solid ${th('colors.border')};
    @media (min-width: md) {
      padding: 0 lg;
    }
  `,
);

const MenuMobileDrawer = styled(Drawer)`
  top: ${`calc(${headerHeight} - 1px)`} !important;
  width: 100% !important;
  @media (min-width: md) {
    width: 12rem !important;
  }
  @media (min-width: xl) {
    display: none;
  }
`;

export const Header = ({ organization, pages }) => {
  const createLink = useRelativeLink();
  const mobileMenuDrawerState = useDrawerState({
    modal: false,
  });

  return (
    <StyledHeader>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        <Drawer.Trigger
          as={Button}
          display={{ xl: 'none' }}
          shape="square"
          size="sm"
          color="white"
          state={mobileMenuDrawerState}
        >
          {mobileMenuDrawerState.visible ? <CrossIcon /> : <MenuIcon />}
        </Drawer.Trigger>
        <Box
          as={Link}
          to={createLink('')}
          alignItems="center"
          alt="Homepage"
          display={{ _: 'none', xl: 'flex' }}
          textDecoration="none"
        >
          <Logo />
        </Box>
        <FilterView flex="1 1 auto" />
        <Text variant="h4">{organization?.name}</Text>
      </Box>
      <Drawer.Backdrop backdropVisible={false} state={mobileMenuDrawerState}>
        <MenuMobileDrawer
          aria-label="Menu backdrop"
          placement="left"
          size="100%"
          state={mobileMenuDrawerState}
        >
          <PagesList
            pages={pages}
            onClick={() => mobileMenuDrawerState.hide()}
          />
        </MenuMobileDrawer>
      </Drawer.Backdrop>
    </StyledHeader>
  );
};

Header.fragment = gql`
  fragment HeaderOrgDetailsFragment on Organization {
    id
    name
  }
`;
