import { useState } from 'react';
import { x, styled } from '@xstyled/styled-components';
import { FaEye, FaEyeSlash, FaCaretRight } from 'react-icons/fa';

const borderStyle = {
  border: 'none',
  borderRadius: 0, // iOS safari and chrome
  borderBottom: '1px solid',
  borderColor: 'gray',
};

const padding = 16;

export const WiFiSSIDInput = ({ onChange, ...rest }) => (
  <x.input
    type="text"
    placeholder="Network name"
    name="ssid"
    minLength={2}
    p={0}
    py={padding}
    w={1}
    {...borderStyle}
    {...rest}
  />
);

export const WiFiPasswordInput = (props) => {
  const [isMasked, setIsMasked] = useState(true);
  const EyeIcon = isMasked ? FaEye : FaEyeSlash;

  return (
    <x.label display="block" {...borderStyle}>
      <x.div display="flex">
        <WiFiSSIDInput
          type={isMasked ? 'password' : 'text'}
          placeholder="Password"
          name="password"
          minLength={8}
          flexGrow={1}
          p={0}
          borderBottom="none"
          {...props}
        />

        <x.div
          p={padding}
          pr={0}
          display="flex"
          alignItems="center"
          role="button"
          aria-label="toggle password visibility"
          onClick={(e) => {
            setIsMasked((prev) => !prev);
            e.preventDefault();
          }}
        >
          <EyeIcon size={20} />
        </x.div>
      </x.div>
    </x.label>
  );
};

const securityTypes = ['NONE', 'WEP', 'WPA/WPA2'];
const defaultSecurity = securityTypes[2];

const StyledSelect = styled.select`
  padding-right: 5;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  color: gray;
  background-color: transparent;
`;

export const WiFiSecurityInput = (props) => (
  <x.label
    display="flex"
    py={padding}
    justifyContent="space-between"
    {...borderStyle}
    {...props}
  >
    Security
    <x.div display="flex" alignItems="center">
      <StyledSelect name="security" defaultValue={defaultSecurity}>
        {securityTypes.map((security) => (
          <x.option key={security} value={security}>
            {security}
          </x.option>
        ))}
      </StyledSelect>

      <FaCaretRight />
    </x.div>
  </x.label>
);
