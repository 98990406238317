import { createTheme } from '@welcome-ui/core';
import { hsl } from 'd3-color';

const DEFAULT_FONT_SIZE = 16;
const DEFAULT_LINE_HEIGHT = 1.15;

const primary = '#23A94E';
const step = 0.3;

const darker = (multiplier) =>
  hsl(primary)
    .darker(step * multiplier)
    .formatHex();

const brighter = (multiplier) =>
  hsl(primary)
    .brighter(step * multiplier)
    .formatHex();

const colors = {
  'primary-100': brighter(6),
  'primary-200': brighter(3),
  'primary-500': primary,
  'primary-600': darker(2),
  'primary-700': darker(3),
  'primary-800': darker(4),
  'primary-900': darker(6),

  'nude-300': '#f8f9fa',

  success: '#27A94E',

  gray: '#575756',
  platinumGray: '#E8E8E8',
};

const toRem = (px) => `${px / DEFAULT_FONT_SIZE}rem`;

const fontFamily =
  'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";';
const fontWeights = {
  semibold: 600,
  bold: 700,
  black: 900,
};

// WelcomeUI Theme props and defaults
// https://www.welcome-ui.com/theming/basics
export const theme = createTheme({
  colors,
  defaultFontFamily: fontFamily,
  headingFontFamily: fontFamily,
  fontWeights,
  texts: {
    'subtitle-lg': {
      lineHeight: DEFAULT_LINE_HEIGHT,
      textTransform: 'uppercase',
      fontSize: toRem(18),
    },
  },
  drawers: {
    default: {
      borderRadius: 0,
    },
  },
  buttons: {
    primary: {
      color: colors['nude-300'],
      borderColor: colors['primary-900'],
    },
    hover: {
      primary: {
        backgroundColor: colors['primary-600'],
        borderColor: colors['primary-900'],
      },
    },
    active: {
      primary: {
        backgroundColor: colors['primary-500'],
        borderColor: colors['primary-500'],
      },
    },
  },
});

theme.chart = {
  axis: {
    ticks: {
      line: {
        stroke: theme.colors['nude-300'],
      },
      text: {
        fill: theme.colors['nude-500'],
      },
    },
  },
  labels: {
    text: {
      fill: theme.colors.white,
      fontSize: theme.fontSizes.md,
    },
  },
  legends: {
    text: {
      fill: theme.colors['dark-700'],
    },
  },
};

theme.sankeyChart = {
  labels: {
    text: {
      fontSize: theme.fontSizes.md,
    },
  },
  tooltip: {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    sourceChip: {
      marginRight: 7,
    },
    targetChip: {
      marginLeft: 7,
      marginRight: 7,
    },
  },
};

theme.spaceToPx = (name) =>
  Number(theme.toPx(theme.space[name].replace('rem', '')).replace('px', ''));
