import { useMemo } from 'react';
import {
  sumBy,
  uniqBy,
  chain,
  capitalize,
  round,
  identity,
  values,
  startCase,
} from 'lodash';

import { colorMap } from '../colors';

const WasteColumns = ['waste', 'recyclable', 'material', 'target'];

const targetValues = {
  current: () => 'Landfill',
  opportunity: (item) => capitalize(item.target.optimized),
  ideal: (item) => capitalize(item.target.ideal),
};

const MaterialsWithCondition = ['paper', 'plastic', 'cardboard'];

const getMaterial = (item) =>
  item.waste.condition && MaterialsWithCondition.includes(item.waste.material)
    ? startCase(`${item.waste.condition} ${item.waste.material}`)
    : capitalize(item.waste.material);

/**
 * Converts aggregatedWaste data to flat tabular data
 * @param {Array} aggregatedData
 * @param {'current'|'opportunity'|'ideal'} target
 * @returns {Array}
 */
const convertToFlatData = (aggregatedData, target) => {
  const getTargetValue = targetValues[target];

  return aggregatedData.map((item) => ({
    waste: 'Waste',
    recyclable: item.target.recyclable ? 'Recyclable' : 'Trash',
    material: getMaterial(item),
    target: getTargetValue(item),
    value: item.count,
  }));
};

/**
 * Merges similar rows with different material into "Other":
 *  1. Group similar rows together and sum their values
 *  2. For all values below the threshold, replace the material with "Other"
 * @param {Array} flatData
 * @param {number} otherMaterialsThreshold
 * @returns {Array}
 */
const mergeRowsBelowThreshold = (flatData, otherMaterialsThreshold) =>
  chain(flatData)
    .groupBy((r) => `${r.waste}#${r.recyclable}#${r.material}#${r.target}`)
    .mapValues((group) => ({
      ...group[0],
      value: sumBy(group, 'value'),
    }))
    .map((row) =>
      row.value > otherMaterialsThreshold ? row : { ...row, material: 'Other' },
    )
    .value();

/**
 * Returns unique nodes and calculates `count` for each one
 */
const getNodes = (flatData, scale, rename) =>
  WasteColumns.flatMap((col) =>
    uniqBy(
      flatData.map((row) => ({ id: row[col], col })),
      'id',
    ),
  ).map(({ id, col }) => ({
    id: rename(id),
    count: scale(
      chain(flatData)
        .filter((row) => row[col] === id)
        .sumBy('value')
        .value(),
    ),
    color: colorMap[id],
  }));

/**
 * Calculate links between sankey nodes.
 */
const getLinks = (flatData, scale, rename) => {
  const links = [];
  for (let i = 0; i < WasteColumns.length - 1; i += 1) {
    const sourceKey = WasteColumns[i];
    const targetKey = WasteColumns[i + 1];

    const newLinks = chain(flatData)
      .groupBy((item) => `${item[sourceKey]}#${item[targetKey]}`)
      .map((group) => ({
        source: rename(group[0][sourceKey]),
        target: rename(group[0][targetKey]),
        value: scale(sumBy(group, 'value')),
      }))
      .value();

    links.push(...newLinks);
  }
  return links;
};

/**
 * @typedef SankeyDataOptions
 * @prop {string} [drillDownLabel]
 * @prop {number} [otherMaterialsThreshold=5]
 * @prop {number} [scale]
 * @prop {function(string): string} [rename]
 */

/**
 * Gets sankey chart data, based on aggregated waste data
 * @param {Array} aggregatedWasteData
 * @param {'current'|'opportunity'|'ideal'} target
 * @param {SankeyDataOptions} [options]
 * @returns {Array|null}
 */
const prepareSankeyData = (aggregatedData, target, options = {}) => {
  const {
    drillDownLabel = null,
    otherMaterialsThreshold = 5,
    scale = 1,
    rename = identity,
  } = options;

  let flatData = convertToFlatData(aggregatedData, target);

  if (otherMaterialsThreshold) {
    flatData = mergeRowsBelowThreshold(flatData, otherMaterialsThreshold);
  }

  if (drillDownLabel) {
    flatData = flatData.filter((row) =>
      WasteColumns.some((col) => rename(row[col]) === drillDownLabel),
    );
  }

  if (!flatData.length) {
    return null;
  }

  const scaleValue = (v) => round(v * scale, 2);
  const nodes = getNodes(flatData, scaleValue, rename);
  const links = getLinks(flatData, scaleValue, rename);

  return { nodes, links };
};

/**
 * Hook to calculate sankey chart data, based on aggregated waste data
 * @param {Array} aggregatedWasteData
 * @param {'current'|'opportunity'|'ideal'} target
 * @param {SankeyDataOptions} [options]
 */
export const useWasteSankeyData = (aggregatedWasteData, target, options) =>
  useMemo(
    () =>
      aggregatedWasteData && target
        ? prepareSankeyData(aggregatedWasteData, target, options)
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aggregatedWasteData, target, ...values(options)],
  );
