import { AnalyticsIcon, CardViewIcon, LineChartIcon } from '@welcome-ui/icons';

import { HomePage } from './home';
import { FlowPage } from './flow';
import { PredictionsPage } from './predictions';
import { SelectWiFiPage } from './select-wifi';

export const home = {
  title: 'Dashboard',
  path: '',
  element: <HomePage />,
  icon: CardViewIcon,
};

export const flow = {
  title: 'Flow',
  path: 'flow',
  element: <FlowPage />,
  icon: LineChartIcon,
};

export const predictions = {
  title: 'Predictions',
  path: 'predictions',
  element: <PredictionsPage />,
  icon: AnalyticsIcon,
};

export const selectWiFi = {
  title: 'Select WiFi',
  path: 'select-wifi/:base64Networks',
  element: <SelectWiFiPage />,
};

export const pages = [home, flow, predictions];

export const hiddenPages = [selectWiFi];
